@import '../../../../node_modules/bootstrap/scss/_variables.scss';

.leaflet-container {
  height: 100%;
  width: 100%;
}
.full-size .leaflet-container {
  height: 100vh;
  width: 100%;
}


.icon-not-complete {
  opacity: 0.3;
}

.tour-map {
  height: 300px;
}

.home-map {
  height: 100vh;
  width: 100%;
}

.overlay-text {
  z-index: 401;
  top: 20%;
  left: 100px;
  max-width: 500px;
  position: absolute;
}

@media only screen and (max-width: map-get($grid-breakpoints, "md")) {
  .tour-map  {
    height: 200px;
  }
}


$marker-cluster-height: 25px;
$marker-cluster-outer-height: $marker-cluster-height * 1.7;

$marker-cluster-medium-height: 30px;
$marker-cluster-medium-outer-height: $marker-cluster-medium-height * 1.7;


.marker-cluster {
  border-radius: 50% !important;  
}

.marker-cluster-small {
  color: white!important;
  background-color: rgba($green, 0.4) !important;
  width: $marker-cluster-outer-height!important;
  height: $marker-cluster-outer-height !important;
  span {
    line-height: $marker-cluster-height !important;
  }

  div {
    width: $marker-cluster-height !important;
    height: $marker-cluster-height !important;
    margin-top: ($marker-cluster-outer-height - $marker-cluster-height) / 2 !important;
    margin-left: ($marker-cluster-outer-height - $marker-cluster-height) / 2  !important;
    background-color: $green !important;
  }
}
.marker-cluster-medium, .marker-cluster-large {
  color: white!important;
  background-color: rgba($primary, 0.4)!important;
  width: $marker-cluster-medium-outer-height!important;
  height: $marker-cluster-medium-outer-height !important;
  span {
    line-height: $marker-cluster-medium-height !important;
    font-weight: bold;
    font-size: 15px;
  }
  div {
    width: $marker-cluster-medium-height !important;
    height: $marker-cluster-medium-height !important;
    margin-top: ($marker-cluster-medium-outer-height - $marker-cluster-medium-height) / 2 !important;
    margin-left: ($marker-cluster-medium-outer-height - $marker-cluster-medium-height) / 2  !important;
    background-color: rgba($primary,1) !important;
  }
}

$icon-size: 100px;

.map-marker-intro {
  color :$green;
  position: absolute;
    left: calc(50% - #{$icon-size / 2});
    top: 36px;
    z-index: 2;
    font-size: $icon-size;
}

.map-intro {
  min-height: 200px;
}